import { Component } from 'preact';
import { html } from 'htm/preact';

import { Caret } from './icons';
import { formatDistance } from './utils';
import { font } from './shared';

// Note: depends on styles injected in the modal component (see .dropp-checkbox).
function Checkbox({ onChange, isChecked, label, style = {} }) {
  return html`
    <div class="dropp-checkbox" onClick=${onChange} style=${style}>
      <input
        type="checkbox"
        checked=${isChecked}
        style=${{ opacity: 0 }}
        onChange=${onChange}
      />
      <label style=${{ margin: 0, padding: 0 }}>${label}</label>
    </div>
  `;
}

export class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLocationDetailOpen: true,
    };
    this.onClickOpeningTime = this.onClickOpeningTime.bind(this);
    this.onClickCaret = this.onClickCaret.bind(this);
  }

  onClickOpeningTime(event) {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  }

  onClickCaret(event) {
    if (!this.props.isSelected) {
      this.props.select();
      this.setState({ isLocationDetailOpen: true });
      return;
    }

    this.setState({ isLocationDetailOpen: !this.state.isLocationDetailOpen });
  }

  componentWillReceiveProps(props) {
    if (!props.isSelected) {
      this.setState({ isOpen: false });
    }
  }

  render({ name, price, distance, availableAt, address, times, isSelected }) {
    const formattedDistance = formatDistance(distance);
    const dayIndex = times.findIndex(time => time.today);
    const openingTime = times[dayIndex].time;

    const storeName = `${name}${price && price > 0 ? ` – ${price} kr.` : ''}`;

    return html`
      <div>
        <div style=${{ display: 'flex', alignItems: 'center' }}>
          <${Checkbox} isChecked=${isSelected} style=${{ marginRight: 12 }} />
          <div style=${{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div
              style=${{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span
                style=${{
                  fontFamily: font,
                  fontSize: '1em',
                  color: '#333',
                  fontWeight: 300,
                }}
              >
                <span class="dropp-location-name-desktop">
                  ${storeName}
                </span>
                <span class="dropp-location-name-mobile">${name}</span>
                ${distance &&
                  html`
                    <span
                      style=${{
                        fontFamily: font,
                        fontSize: `${14 / 16}em`,
                        color: '#333',
                        marginLeft: 6,
                      }}
                      >(${formattedDistance} km)</span
                    >
                  `}
              </span>
              <div
                style=${{
                  display: 'flex',
                  fontSize: `${14 / 16}em`,
                  fontWeight: 300,
                  color: '#717171',
                }}
              >
                <span>
                  ${availableAt}
                </span>
                <span>
                  <${Caret}
                    onClick=${this.onClickCaret}
                    styles=${{
                      cursor: 'pointer',
                      marginLeft: 21,
                      marginRight: 15,
                      transform: isSelected
                        ? this.state.isLocationDetailOpen
                          ? 'rotate(180deg)'
                          : 'none'
                        : 'none',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        ${isSelected &&
          this.state.isLocationDetailOpen &&
          html`
            <div
              style=${{
                marginLeft: 24,
                fontSize: `${14 / 16}em`,
                color: '#717171',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div>${address}</div>
              <div onClick=${this.onClickOpeningTime} style=${{ marginTop: 8 }}>
                ${html`
                  ${openingTime}
                  <${Caret}
                    styles=${{
                      cursor: 'pointer',
                      marginLeft: 6,
                      transform: this.state.isOpen ? 'rotate(180deg)' : 'none',
                    }}
                  />
                `}
                ${this.state.isOpen &&
                  html`
                    <div style=${{ marginTop: 12 }}>
                      ${times.map(
                        (t, index) => html`
                          <div
                            style=${{
                              marginTop: 6,
                              fontWeight: index === dayIndex ? 400 : 300,
                              color: index === dayIndex ? '#333' : 'inherit',
                            }}
                          >
                            ${t.name}: ${t.time}
                          </div>
                        `,
                      )}
                    </div>
                  `}
              </div>
            </div>
          `}
      </div>
    `;
  }
}
