import { html } from 'htm/preact';

import { colors, fonts } from '../constants';
import { font } from './shared';

export function Button({ children, css, color, ...rest }) {
  return html`
    <button
      style=${{
        fontFamily: font,
        fontSize: 12,
        fontWeight: 500,
        color: colors.white,
        backgroundColor: rest.disabled ? '#949494' : color,
        height: 32,
        border: 'none',
        ...css,
      }}
      ...${rest}
    >
      ${children}
    </button>
  `;
}
