export function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = event => resolve(event);
    script.onerror = error => reject(error);
    document.head.appendChild(script);
  });
}

export function loadStyles(href) {
  return new Promise((resolve, reject) => {
    const styles = document.createElement('link');
    styles.href = href;
    styles.rel = 'stylesheet';
    styles.onload = event => resolve(event);
    styles.onerror = error => reject(error);
    document.head.appendChild(styles);
  });
}

export function createStyles(css) {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.type = 'text/css';
  head.appendChild(style);
  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

export function formatDistance(number) {
  return (Math.round(number * 10) / 10).toString().replace('.', ',');
}
