/**
 * Dropp locations modal - used by web stores to display Dropp locations.
 *
 * This module is included on a website and can be opened by calling the
 * `chooseDroppLocations` function, which is exposed on the `window`
 * object. This function returns a promise which resolves to a location's
 * UUID or null if the modal was dismissed without selecting a location.
 * This location id is required for stores to submit an order into our
 * systems.
 *
 * The modal itself is rendered as a Preact app, using the htm library,
 * instead of React. We use Preact over React in order to limit the size
 * of the script, which is already large due to other dependencies that
 * are required.
 *
 * The main dependencies that this modal loads are the JavaScript Mapbox
 * SDK, to display the map; the Mapbox Geocoder, for the search bar; and
 * Turf.js, to calculate distances between two points.
 *
 * To trace the logic, start by inspecting the contents of the Modal
 * component.
 */

import { render } from 'preact';
import { html } from 'htm/preact';

import { Modal } from './modal';

// API token for Mapbox.
const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiZHJvcHAiLCJhIjoiY2p6OW9kOXNlMDAxOTNvcGRyYXdnNDhldyJ9.Oc_1kt8CNF-Pmlt_l18fEg';
const MAPBOX_STYLE = 'mapbox://styles/mapbox/light-v10';

// Co-ordinates of the bounds of Iceland, used to determine the center
// of the map as well as for filtering queries in Geocoder.
// Uses the co-ordinates of extreme points of Iceland,
// see https://en.wikipedia.org/wiki/List_of_extreme_points_of_Iceland
const ICELAND_BOUNDS = [[-24.533333, 63.283333], [-13.233333, 67.135833]];
const CAPITAL_REGION_BOUNDS = [[-22.2, 64.0], [-21.62, 64.2]];

const dataset = document.currentScript && document.currentScript.dataset;

let storeId = dataset ? dataset.storeId : null;
let env = dataset ? dataset.env : null;

try {
  const index = document.currentScript.src.match(/\?/).index;
  const params = new URLSearchParams(document.currentScript.src.slice(index));
  if (!storeId) {
    storeId = params.get('data-store-id');
  }
  if (!env) {
    env = params.get('data-env');
  }
} catch (error) {}

if (env !== 'api' && env !== 'stage') {
  env = 'api';
}

let locations;
let colors = {
  nav: '#171349',
  button: '#67B419',
};
function fetchLocations() {
  const url = `https://${env}.dropp.is/dropp/api/v1/dropp/locations${
    storeId ? `?store=${storeId}` : ''
  }`;
  return fetch(url, { mode: 'cors' })
    .then(res => res.json())
    .then(response => {
      locations = response.locations.map(loc => ({
        ...loc,
        coordinates: [loc.gpsLongitude, loc.gpsLatitude],
      }));
      colors = response.color;
    });
}

fetchLocations();

window.chooseDroppLocation = function(options = {}) {
  return new Promise((resolve, reject) => {
    try {
      const container = document.createElement('div');
      document.body.appendChild(container);

      function onSelectLocation(location) {
        document.body.removeChild(container);
        resolve(location);
      }

      function onDismiss() {
        document.body.removeChild(container);
        resolve(null);
      }

      render(
        html`
          <${Modal}
            locations=${locations}
            colors=${colors}
            options=${options}
            mapboxAccessToken=${MAPBOX_ACCESS_TOKEN}
            mapStyle=${MAPBOX_STYLE}
            bounds=${ICELAND_BOUNDS}
            icelandBounds=${ICELAND_BOUNDS}
            onSelectLocation=${onSelectLocation}
            onDismiss=${onDismiss}
          />
        `,
        container,
      );
    } catch (error) {
      reject(error);
    }
  });
};
