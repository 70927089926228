export const fonts = {
  display: 'GT Eesti Pro Display',
  text: 'GT Eesti Pro Text',
};

export const colors = {
  teal: '#00ffff',
  blue: '#00007d',
  paleBlue: '#40409d',
  background: '#f2f2f6',
  red: '#f3696b',
  paleRed: '#ffcad1',
  grey: '#777',
  lightGrey: '#bdbdbd',
  white: '#fff',
  green: '#15b317',
};

export const zIndices = {
  default: 0,
  basse: 1,
  navigation: 2,
  dropdown: 3,
  modal: 4,
};
