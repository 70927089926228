import { html } from 'htm/preact';

import { colors } from '../constants';

export function Caret({ onClick, styles }) {
  return html`
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick=${onClick}
      style=${styles}
    >
      <line
        x1="0.353553"
        y1="0.646447"
        x2="6.05118"
        y2="6.34408"
        stroke="#828282"
      />
      <line
        x1="5.64645"
        y1="6.34408"
        x2="11.3441"
        y2="0.646448"
        stroke="#828282"
      />
    </svg>
  `;
}

export function Expand({ onClick }) {
  return html`
    <div
      style=${{
        display: 'flex',
        padding: 4,
        backgroundColor: '#fff',
        borderRadius: '50%',
        marginRight: 8,
        cursor: 'pointer',
        boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.2)',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="${colors.paleBlue}"
        onClick=${onClick}
      >
        <defs><path id="a" d="M0 0h24v24H0z" /></defs>
        <clipPath id="b"><use xlink:href="#a" overflow="visible" /></clipPath>
        <path
          clip-path="url(#b)"
          d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z"
        />
        <path clip-path="url(#b)" fill="none" d="M0 0h24v24H0z" />
      </svg>
    </div>
  `;
}

export function MyLocation({ onClick }) {
  return html`
    <div
      style=${{
        display: 'flex',
        padding: 4,
        backgroundColor: '#fff',
        borderRadius: '50%',
        marginRight: 8,
        cursor: 'pointer',
        boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.2)',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="${colors.paleBlue}"
        onClick=${onClick}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
        />
      </svg>
    </div>
  `;
}

export function Close({ onClick, className }) {
  return html`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#fff"
      viewBox="0 0 24 24"
      style=${{ marginRight: 35 }}
      onClick=${onClick}
      role="button"
      class=${className}
    >
      <path
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </svg>
  `;
}
